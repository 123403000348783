<template>
  <v-card :readonly="!mailChimpIntegration.Active" flat>
    <v-card-actions class="pa-0">
      <v-checkbox
        v-model="mailChimpIntegration.Active"
        label="MailChimp"
      ></v-checkbox>

      <v-spacer></v-spacer>

      <v-btn
        depressed
        icon
        :disabled="!mailChimpIntegration.Active"
        @click="integrationShow = !integrationShow"
      >
        <v-icon>{{
          integrationShow ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-if="integrationShow">
        <v-row no-gutters>
          <v-col class="mr-2">
            <v-text-field
              v-model="mailChimpIntegration['ApiKey']"
              outlined
              dense
              :append-icon="integrationShowKey ? 'mdi-eye' : 'mdi-eye-off'"
              :type="integrationShowKey ? 'text' : 'password'"
              :label="$t('API key')"
              @click:append="integrationShowKey = !integrationShowKey"
            ></v-text-field>
          </v-col>
          <v-col class="ml-2">
            <v-text-field
              v-model="mailChimpIntegration['ListId']"
              outlined
              dense
              :label="$t('List id')"
            ></v-text-field>
          </v-col>
        </v-row>
        <h4 class="mb-3">{{ $t("Merge fields") }}</h4>

        <v-card v-for="(mergeField, index) in getMergeFields" :key="index" flat>
          <v-card-text class="pa-0">
            <v-row no-gutters align="center">
              <v-col cols="5" class="mr-2 my-2">
                <p class="mb-0">{{ mergeField.DataProperty }}</p>
              </v-col>
              <v-col cols="5" class="ml-2 my-2">
                <p class="mb-0">
                  {{ mergeField.MergeField }}
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="d-flex align-center justify-end">
                <v-btn depressed icon color="primaryred" @click="deleteItem(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12"> <v-divider></v-divider> </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <v-col cols="5" class="mr-2 py-2">
              <v-select
                v-model="integrationDefinitionKeys"
                :items="getAllContentDefinitionKeys"
                :label="$t('Merge key')"
                :placeholder="$t('Select merge key')"
                required
                clearable
                :rules="getRules('notEmptyStringRule')"
                hide-details="auto"
                outlined
                dense
              >
                <template v-slot:selection="{ item }">
                  {{ item.Name[currentLanguage] }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.Name[currentLanguage] }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="5" class="ml-2 py-2">
              <v-text-field
                prefix="*|"
                v-model="integrationItemCode"
                :label="$t('Merge field text')"
                :rules="getRules('notEmptyUpperLowerAlfabeticRule')"
                required
                suffix="|*"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1" class="d-flex align-start justify-end mt-2">
              <v-btn
                depressed
                @click="addToMergeFieldsArray()"
                icon
                color="primarygreen"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import validation from "../../validation";
export default {
  props: ["page", "currentLanguage"],
  data() {
    return {
      integrationShow: false,
      integrationShowKey: false,
      integrationItemCode: null,
      integrationDefinitionKeys: null,
      mailChimpIntegration: {
        ApiKey: "",
        Active: false,
        ListId: "",
        DataPropertiesMergeFields: [],
      },
      valid: true,
    };
  },
  created() {
    if (this.page.MailChimpIntegration) {
      this.$set(
        this.mailChimpIntegration,
        "ApiKey",
        this.page.MailChimpIntegration.ApiKey
      );
      this.$set(
        this.mailChimpIntegration,
        "Active",
        this.page.MailChimpIntegration.Active
      );

      this.$set(
        this.mailChimpIntegration,
        "ListId",
        this.page.MailChimpIntegration.ListId
      );
      this.$set(
        this.mailChimpIntegration,
        "DataPropertiesMergeFields",
        this.page.MailChimpIntegration.DataPropertiesMergeFields
      );
    }
  },
  computed: {
    getIntegrations() {
      return this.page.MailChimpIntegration;
    },
    getMergeFields() {
      return this.mailChimpIntegration["DataPropertiesMergeFields"];
    },
    getAllContentDefinitionKeys() {
      let items = [];
      if (this.page.ContentDefinition) {
        for (const key in this.page.ContentDefinition) {
          if (
            this.page.ContentDefinition[key].Type == "number" ||
            this.page.ContentDefinition[key].Type == "text"
          ) {
            if (
              Object.hasOwnProperty.call(
                this.page.ContentDefinition[key],
                "TranslatedTitle"
              )
            ) {
              /* if (this.content.Data[key].TranslatedTitle[this.currentLanguage]!="") { } */
              items.push({
                Name: this.page.ContentDefinition[key].TranslatedTitle,
                Key: key,
              });
            } else if (
              Object.hasOwnProperty.call(
                this.page.ContentDefinition[key],
                "Title"
              )
            ) {
              const lang = this.currentLanguage;
              const obj = {};
              obj[lang] = this.page.ContentDefinition[key].Title;
              items.push({
                Name: [obj],
                Key: key,
              });
            }
          }
        }
      }
      return items;
    },
  },
  watch: {
    "mailChimpIntegration.ApiKey": function () {
      this.$emit("updatePageMailchimpIntegration", this.mailChimpIntegration);
    },
    "mailChimpIntegration.Active": function () {
      this.integrationShow = this.mailChimpIntegration.Active;

      this.$emit("updatePageMailchimpIntegration", this.mailChimpIntegration);
    },
    "mailChimpIntegration.ListId": function () {
      this.$emit("updatePageMailchimpIntegration", this.mailChimpIntegration);
    },
  },

  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    addToMergeFieldsArray() {
      if (!this.$refs.form.validate()) return;
      let dataPropertyKey = "";
      if (typeof this.integrationDefinitionKeys === "object") {
        dataPropertyKey = this.integrationDefinitionKeys.Key;
      } else {
        dataPropertyKey = this.integrationDefinitionKeys;
      }
      this.mailChimpIntegration["DataPropertiesMergeFields"].push({
        DataProperty: dataPropertyKey,
        MergeField: "*|" + this.integrationItemCode.toUpperCase() + "|*",
      });
      this.integrationDefinitionKeys = null;
      this.integrationItemCode = null;
      this.$emit("updatePageMailchimpIntegration", this.mailChimpIntegration);
    },

    deleteItem(i) {
      this.$delete(this.mailChimpIntegration["DataPropertiesMergeFields"], i);
    },
  },
};
</script>

<style>
</style>